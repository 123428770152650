export const ZZZAssault_3 = [
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 11.67,
    rank: 1,
    avg_round: 21655,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 9.75,
    rank: 2,
    avg_round: 28956,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 5.9,
    rank: 3,
    avg_round: 18041,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 5.61,
    rank: 4,
    avg_round: 25965,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 4.08,
    rank: 5,
    avg_round: 18804,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 3.85,
    rank: 6,
    avg_round: 18151,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 3.81,
    rank: 7,
    avg_round: 24474,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 3.02,
    rank: 8,
    avg_round: 22848,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 2.24,
    rank: 9,
    avg_round: 16440,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 2.24,
    rank: 9,
    avg_round: 18053,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 2.09,
    rank: 11,
    avg_round: 18944,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 2.09,
    rank: 11,
    avg_round: 21915,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 2.01,
    rank: 13,
    avg_round: 22826,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.95,
    rank: 14,
    avg_round: 19748,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.68,
    rank: 15,
    avg_round: 21027,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.66,
    rank: 16,
    avg_round: 18310,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 1.53,
    rank: 17,
    avg_round: 20288,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 1.47,
    rank: 18,
    avg_round: 14289,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.33,
    rank: 19,
    avg_round: 17137,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.28,
    rank: 20,
    avg_round: 21986,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 1.22,
    rank: 21,
    avg_round: 14510,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.99,
    rank: 22,
    avg_round: 15797,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.97,
    rank: 23,
    avg_round: 20291,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.85,
    rank: 24,
    avg_round: 18753,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.83,
    rank: 25,
    avg_round: 21300,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.81,
    rank: 26,
    avg_round: 24736,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.68,
    rank: 27,
    avg_round: 11336,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.68,
    rank: 27,
    avg_round: 21718,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.66,
    rank: 29,
    avg_round: 18064,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.66,
    rank: 29,
    avg_round: 16912,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.62,
    rank: 31,
    avg_round: 13769,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.56,
    rank: 32,
    avg_round: 10509,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.56,
    rank: 32,
    avg_round: 16684,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.46,
    rank: 34,
    avg_round: 15736,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.44,
    rank: 35,
    avg_round: 17699,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.43,
    rank: 36,
    avg_round: 21111,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.37,
    rank: 37,
    avg_round: 23327,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.35,
    rank: 38,
    avg_round: 21012,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.35,
    rank: 38,
    avg_round: 17749,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.33,
    rank: 40,
    avg_round: 19319,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.31,
    rank: 41,
    avg_round: 17913,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.31,
    rank: 41,
    avg_round: 18850,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.31,
    rank: 41,
    avg_round: 12557,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.31,
    rank: 41,
    avg_round: 12996,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.29,
    rank: 45,
    avg_round: 19511,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.27,
    rank: 46,
    avg_round: 12932,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.27,
    rank: 46,
    avg_round: 14644,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.27,
    rank: 46,
    avg_round: 14250,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 49,
    avg_round: 21926,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 49,
    avg_round: 19330,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.23,
    rank: 49,
    avg_round: 10805,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.23,
    rank: 49,
    avg_round: 17641,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 49,
    avg_round: 12694,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 54,
    avg_round: 23545,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 54,
    avg_round: 17306,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 56,
    avg_round: 16438,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'pulchra',
    app_rate: 0.19,
    rank: 56,
    avg_round: 13774,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 56,
    avg_round: 14398,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.19,
    rank: 56,
    avg_round: 11430,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 60,
    avg_round: 22505,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'astra-yao',
    char_three: 'ben',
    app_rate: 0.17,
    rank: 60,
    avg_round: 21883,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 60,
    avg_round: 12530,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.17,
    rank: 60,
    avg_round: 13327,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 64,
    avg_round: 18184,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 64,
    avg_round: 20956,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 64,
    avg_round: 20370,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 64,
    avg_round: 12013,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.15,
    rank: 64,
    avg_round: 24266,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.15,
    rank: 64,
    avg_round: 14587,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 64,
    avg_round: 15334,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 71,
    avg_round: 11389,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 71,
    avg_round: 14906,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.12,
    rank: 73,
    avg_round: 11877,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.12,
    rank: 73,
    avg_round: 17485,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 73,
    avg_round: 14599,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 73,
    avg_round: 20990,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 73,
    avg_round: 13994,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 73,
    avg_round: 17071,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 73,
    avg_round: 22702,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 73,
    avg_round: 19471,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 73,
    avg_round: 18085,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.12,
    rank: 73,
    avg_round: 10723,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 83,
    avg_round: 11014,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 83,
    avg_round: 17734,
    star_num: '4'
  },
  {
    char_one: 'koleda',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.1,
    rank: 83,
    avg_round: 11022,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 83,
    avg_round: 21413,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 83,
    avg_round: 22148,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 83,
    avg_round: 19086,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.1,
    rank: 83,
    avg_round: 10511,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 83,
    avg_round: 18022,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.08,
    rank: 91,
    avg_round: 10063,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 91,
    avg_round: 13992,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 91,
    avg_round: 9946,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 91,
    avg_round: 13071,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 91,
    avg_round: 16402,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 91,
    avg_round: 15802,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 91,
    avg_round: 13540,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 91,
    avg_round: 15198,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 91,
    avg_round: 18067,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.08,
    rank: 91,
    avg_round: 13158,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.08,
    rank: 91,
    avg_round: 15957,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 91,
    avg_round: 19129,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.08,
    rank: 91,
    avg_round: 14112,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 91,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 91,
    avg_round: 9358,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 91,
    avg_round: 22616,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 91,
    avg_round: 17062,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'koleda',
    app_rate: 0.08,
    rank: 91,
    avg_round: 12308,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 91,
    avg_round: 9488,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 110,
    avg_round: 20930,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 110,
    avg_round: 22732,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 110,
    avg_round: 21458,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 110,
    avg_round: 12776,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 110,
    avg_round: 19077,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 18005,
    star_num: '4'
  },
  {
    char_one: 'lucy',
    char_two: 'ben',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 110,
    avg_round: 12950,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 110,
    avg_round: 18740,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 110,
    avg_round: 15360,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 110,
    avg_round: 9360,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 110,
    avg_round: 17971,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 110,
    avg_round: 10726,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 110,
    avg_round: 8489,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 110,
    avg_round: 12136,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 12222,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 10218,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 110,
    avg_round: 16110,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 110,
    avg_round: 19450,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 110,
    avg_round: 17921,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 110,
    avg_round: 21440,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 22041,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 110,
    avg_round: 8275,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 110,
    avg_round: 10841,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'lighter',
    app_rate: 0.06,
    rank: 110,
    avg_round: 16227,
    star_num: '4'
  }
];
