export const ZZZAssault_0 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 19.05,
    rank: 1,
    avg_round: 23505,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 17.22,
    rank: 2,
    avg_round: 25053,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 10.52,
    rank: 3,
    avg_round: 21665,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 8.91,
    rank: 4,
    avg_round: 30180,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 8.83,
    rank: 5,
    avg_round: 28910,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 7.17,
    rank: 6,
    avg_round: 21070,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 6.17,
    rank: 7,
    avg_round: 31430,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 5.81,
    rank: 8,
    avg_round: 18030,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 5.0,
    rank: 9,
    avg_round: 25965,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 4.74,
    rank: 10,
    avg_round: 36329,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 4.53,
    rank: 11,
    avg_round: 26276,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 4.29,
    rank: 12,
    avg_round: 22179,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 4.12,
    rank: 13,
    avg_round: 18880,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 3.81,
    rank: 14,
    avg_round: 27998,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 3.57,
    rank: 15,
    avg_round: 18060,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 3.55,
    rank: 16,
    avg_round: 24621,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 3.1,
    rank: 17,
    avg_round: 34926,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 2.97,
    rank: 18,
    avg_round: 30580,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 2.76,
    rank: 19,
    avg_round: 22802,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 2.76,
    rank: 19,
    avg_round: 11368,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 2.5,
    rank: 21,
    avg_round: 26017,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 2.24,
    rank: 22,
    avg_round: 20740,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 2.19,
    rank: 23,
    avg_round: 24919,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 2.02,
    rank: 24,
    avg_round: 16375,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 2.02,
    rank: 24,
    avg_round: 17980,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 1.97,
    rank: 26,
    avg_round: 29561,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.97,
    rank: 26,
    avg_round: 15827,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.9,
    rank: 28,
    avg_round: 18939,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.9,
    rank: 28,
    avg_round: 21958,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.9,
    rank: 28,
    avg_round: 22689,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.84,
    rank: 31,
    avg_round: 22529,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 1.83,
    rank: 32,
    avg_round: 26720,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 1.81,
    rank: 33,
    avg_round: 30074,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.78,
    rank: 34,
    avg_round: 19725,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.76,
    rank: 35,
    avg_round: 22702,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 1.74,
    rank: 36,
    avg_round: 30169,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 1.64,
    rank: 37,
    avg_round: 21192,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 1.59,
    rank: 38,
    avg_round: 21935,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.57,
    rank: 39,
    avg_round: 18012,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 1.57,
    rank: 39,
    avg_round: 24054,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 1.52,
    rank: 41,
    avg_round: 23169,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.5,
    rank: 42,
    avg_round: 21027,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 1.5,
    rank: 42,
    avg_round: 24944,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 1.47,
    rank: 44,
    avg_round: 30814,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 1.45,
    rank: 45,
    avg_round: 22874,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 1.43,
    rank: 46,
    avg_round: 14290,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 1.38,
    rank: 47,
    avg_round: 18418,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 1.38,
    rank: 47,
    avg_round: 15112,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 1.36,
    rank: 49,
    avg_round: 25658,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.34,
    rank: 50,
    avg_round: 15687,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 1.31,
    rank: 51,
    avg_round: 14289,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 1.28,
    rank: 52,
    avg_round: 24179,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.22,
    rank: 53,
    avg_round: 25970,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.21,
    rank: 54,
    avg_round: 17098,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.19,
    rank: 55,
    avg_round: 22010,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 1.14,
    rank: 56,
    avg_round: 26066,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 1.12,
    rank: 57,
    avg_round: 28636,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 1.0,
    rank: 58,
    avg_round: 21533,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.98,
    rank: 59,
    avg_round: 22597,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.97,
    rank: 60,
    avg_round: 22654,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.95,
    rank: 61,
    avg_round: 13681,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.9,
    rank: 62,
    avg_round: 22206,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.88,
    rank: 63,
    avg_round: 15797,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.88,
    rank: 63,
    avg_round: 20799,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.86,
    rank: 65,
    avg_round: 27739,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.86,
    rank: 65,
    avg_round: 20291,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.86,
    rank: 65,
    avg_round: 23781,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.81,
    rank: 68,
    avg_round: 17513,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.79,
    rank: 69,
    avg_round: 11612,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'caesar',
    app_rate: 0.79,
    rank: 69,
    avg_round: 22182,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.79,
    rank: 69,
    avg_round: 34633,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.79,
    rank: 69,
    avg_round: 14208,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.78,
    rank: 73,
    avg_round: 18753,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.76,
    rank: 74,
    avg_round: 23244,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.76,
    rank: 74,
    avg_round: 25579,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.74,
    rank: 76,
    avg_round: 19991,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.74,
    rank: 76,
    avg_round: 24752,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.74,
    rank: 76,
    avg_round: 24637,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.74,
    rank: 76,
    avg_round: 20575,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.74,
    rank: 76,
    avg_round: 21300,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.72,
    rank: 81,
    avg_round: 19383,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.72,
    rank: 81,
    avg_round: 28485,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.72,
    rank: 81,
    avg_round: 16649,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.69,
    rank: 84,
    avg_round: 25527,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.67,
    rank: 85,
    avg_round: 18745,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.67,
    rank: 85,
    avg_round: 25554,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.67,
    rank: 85,
    avg_round: 12733,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.62,
    rank: 88,
    avg_round: 27137,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.6,
    rank: 89,
    avg_round: 21718,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.6,
    rank: 89,
    avg_round: 26802,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.59,
    rank: 91,
    avg_round: 23580,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.59,
    rank: 91,
    avg_round: 18064,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.57,
    rank: 93,
    avg_round: 32539,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'rina',
    app_rate: 0.57,
    rank: 93,
    avg_round: 15956,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.55,
    rank: 95,
    avg_round: 16499,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'nicole-demara',
    app_rate: 0.55,
    rank: 95,
    avg_round: 20471,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.55,
    rank: 95,
    avg_round: 24960,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.52,
    rank: 98,
    avg_round: 20316,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.52,
    rank: 98,
    avg_round: 16432,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.52,
    rank: 98,
    avg_round: 16394,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.48,
    rank: 101,
    avg_round: 18810,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.47,
    rank: 102,
    avg_round: 21311,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.45,
    rank: 103,
    avg_round: 21398,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.45,
    rank: 103,
    avg_round: 23534,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.43,
    rank: 105,
    avg_round: 11285,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 105,
    avg_round: 24375,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.43,
    rank: 105,
    avg_round: 19935,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 105,
    avg_round: 17639,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.41,
    rank: 109,
    avg_round: 15736,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.41,
    rank: 109,
    avg_round: 29750,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.4,
    rank: 111,
    avg_round: 29969,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.4,
    rank: 111,
    avg_round: 17934,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.4,
    rank: 111,
    avg_round: 18899,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.4,
    rank: 111,
    avg_round: 20888,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.36,
    rank: 115,
    avg_round: 19710,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.36,
    rank: 115,
    avg_round: 22580,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 115,
    avg_round: 27651,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.36,
    rank: 115,
    avg_round: 21232,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.34,
    rank: 119,
    avg_round: 19706,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.33,
    rank: 120,
    avg_round: 14578,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.33,
    rank: 120,
    avg_round: 31977,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.31,
    rank: 122,
    avg_round: 17749,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'astra-yao',
    app_rate: 0.31,
    rank: 122,
    avg_round: 25167,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.31,
    rank: 122,
    avg_round: 12330,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.31,
    rank: 122,
    avg_round: 26822,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.31,
    rank: 122,
    avg_round: 19017,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.29,
    rank: 127,
    avg_round: 12784,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.29,
    rank: 127,
    avg_round: 25613,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.29,
    rank: 127,
    avg_round: 12683,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.29,
    rank: 127,
    avg_round: 24861,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.29,
    rank: 127,
    avg_round: 22663,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.28,
    rank: 132,
    avg_round: 17913,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 132,
    avg_round: 11398,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.28,
    rank: 132,
    avg_round: 18850,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.28,
    rank: 132,
    avg_round: 30483,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.28,
    rank: 132,
    avg_round: 19015,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.28,
    rank: 132,
    avg_round: 12996,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.26,
    rank: 138,
    avg_round: 10120,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.26,
    rank: 138,
    avg_round: 21929,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.26,
    rank: 138,
    avg_round: 15811,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.26,
    rank: 138,
    avg_round: 19511,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.26,
    rank: 138,
    avg_round: 16196,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.24,
    rank: 143,
    avg_round: 20482,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.24,
    rank: 143,
    avg_round: 25221,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.24,
    rank: 143,
    avg_round: 15952,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.24,
    rank: 143,
    avg_round: 21129,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.24,
    rank: 143,
    avg_round: 15399,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.24,
    rank: 143,
    avg_round: 22519,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.24,
    rank: 143,
    avg_round: 14644,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 150,
    avg_round: 18150,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.22,
    rank: 150,
    avg_round: 13518,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.22,
    rank: 150,
    avg_round: 24159,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 150,
    avg_round: 19483,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.22,
    rank: 150,
    avg_round: 20446,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.22,
    rank: 150,
    avg_round: 17998,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 156,
    avg_round: 21926,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 156,
    avg_round: 14074,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 156,
    avg_round: 19330,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 156,
    avg_round: 15219,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 156,
    avg_round: 24769,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.21,
    rank: 156,
    avg_round: 11358,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 156,
    avg_round: 12694,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 156,
    avg_round: 20518,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.21,
    rank: 156,
    avg_round: 20211,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.19,
    rank: 165,
    avg_round: 20539,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 165,
    avg_round: 17615,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 165,
    avg_round: 23346,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.19,
    rank: 165,
    avg_round: 18469,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 165,
    avg_round: 17705,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 165,
    avg_round: 25720,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 165,
    avg_round: 17306,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 165,
    avg_round: 21434,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.19,
    rank: 165,
    avg_round: 18576,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 174,
    avg_round: 21135,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 174,
    avg_round: 16438,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'pulchra',
    app_rate: 0.17,
    rank: 174,
    avg_round: 13774,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.17,
    rank: 174,
    avg_round: 15410,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 174,
    avg_round: 11338,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 174,
    avg_round: 18442,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 174,
    avg_round: 13681,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.17,
    rank: 174,
    avg_round: 19675,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 183,
    avg_round: 22111,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 183,
    avg_round: 20320,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 183,
    avg_round: 17141,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 183,
    avg_round: 22505,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'astra-yao',
    char_three: 'ben',
    app_rate: 0.16,
    rank: 183,
    avg_round: 21883,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.16,
    rank: 183,
    avg_round: 27791,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 183,
    avg_round: 24720,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.16,
    rank: 183,
    avg_round: 23191,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 183,
    avg_round: 19641,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 183,
    avg_round: 12114,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.16,
    rank: 183,
    avg_round: 12530,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 183,
    avg_round: 26636,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.16,
    rank: 183,
    avg_round: 9574,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 183,
    avg_round: 8456,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'lycaon',
    app_rate: 0.16,
    rank: 183,
    avg_round: 15303,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 198,
    avg_round: 14017,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.14,
    rank: 198,
    avg_round: 14319,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 198,
    avg_round: 20370,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 198,
    avg_round: 23245,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 198,
    avg_round: 19461,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 198,
    avg_round: 19830,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 198,
    avg_round: 17983,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 198,
    avg_round: 17523,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 198,
    avg_round: 18771,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.14,
    rank: 198,
    avg_round: 14587,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 198,
    avg_round: 21772,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 198,
    avg_round: 22114,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.14,
    rank: 198,
    avg_round: 16737,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 198,
    avg_round: 21114,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 198,
    avg_round: 29005,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 198,
    avg_round: 13928,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.12,
    rank: 214,
    avg_round: 21251,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 214,
    avg_round: 19725,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 214,
    avg_round: 24608,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.12,
    rank: 214,
    avg_round: 21889,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 214,
    avg_round: 18706,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 214,
    avg_round: 24296,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 214,
    avg_round: 24952,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 214,
    avg_round: 21004,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 214,
    avg_round: 20310,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 214,
    avg_round: 14906,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 214,
    avg_round: 18382,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 214,
    avg_round: 19218,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 214,
    avg_round: 15396,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 214,
    avg_round: 19387,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 214,
    avg_round: 19480,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.12,
    rank: 214,
    avg_round: 10065,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.12,
    rank: 214,
    avg_round: 9982,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 232,
    avg_round: 22251,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 232,
    avg_round: 24973,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.1,
    rank: 232,
    avg_round: 11877,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 232,
    avg_round: 18709,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.1,
    rank: 232,
    avg_round: 17485,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.1,
    rank: 232,
    avg_round: 14014,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 232,
    avg_round: 11512,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 232,
    avg_round: 21681,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 232,
    avg_round: 22752,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 232,
    avg_round: 13242,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 232,
    avg_round: 18042,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 232,
    avg_round: 18920,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 232,
    avg_round: 22702,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 232,
    avg_round: 17972,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 232,
    avg_round: 12454,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 232,
    avg_round: 17712,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 232,
    avg_round: 19614,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 232,
    avg_round: 21571,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 232,
    avg_round: 15326,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 232,
    avg_round: 18458,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 232,
    avg_round: 15317,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 232,
    avg_round: 16383,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 232,
    avg_round: 19471,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 232,
    avg_round: 20283,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 232,
    avg_round: 18085,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 232,
    avg_round: 19379,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 232,
    avg_round: 17204,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.1,
    rank: 232,
    avg_round: 10723,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 260,
    avg_round: 16670,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 260,
    avg_round: 15222,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 260,
    avg_round: 15680,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 260,
    avg_round: 10808,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 260,
    avg_round: 10524,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 260,
    avg_round: 17076,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'pulchra',
    app_rate: 0.09,
    rank: 260,
    avg_round: 20270,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 260,
    avg_round: 21677,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 260,
    avg_round: 16593,
    star_num: '4'
  },
  {
    char_one: 'koleda',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.09,
    rank: 260,
    avg_round: 11022,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 260,
    avg_round: 13836,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 260,
    avg_round: 15972,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 260,
    avg_round: 22237,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 260,
    avg_round: 20324,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 260,
    avg_round: 12734,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.09,
    rank: 260,
    avg_round: 13238,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.09,
    rank: 260,
    avg_round: 15310,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.09,
    rank: 260,
    avg_round: 10511,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 260,
    avg_round: 9468,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 260,
    avg_round: 16954,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'grace-howard',
    app_rate: 0.09,
    rank: 260,
    avg_round: 18032,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 282,
    avg_round: 13345,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 282,
    avg_round: 13992,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 282,
    avg_round: 24336,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 282,
    avg_round: 9946,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 282,
    avg_round: 16057,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 282,
    avg_round: 16402,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 282,
    avg_round: 15802,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 282,
    avg_round: 11722,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 282,
    avg_round: 19100,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 282,
    avg_round: 11642,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 282,
    avg_round: 18338,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 282,
    avg_round: 11529,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.07,
    rank: 282,
    avg_round: 15957,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 282,
    avg_round: 14138,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 282,
    avg_round: 14450,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.07,
    rank: 282,
    avg_round: 9810,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 282,
    avg_round: 21839,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 282,
    avg_round: 14831,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 282,
    avg_round: 17062,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'koleda',
    app_rate: 0.07,
    rank: 282,
    avg_round: 12308,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 282,
    avg_round: 14676,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 282,
    avg_round: 7770,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 282,
    avg_round: 21107,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 282,
    avg_round: 9488,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 319,
    avg_round: 30468,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 319,
    avg_round: 24312,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 319,
    avg_round: 19077,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 319,
    avg_round: 22458,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 23280,
    star_num: '4'
  },
  {
    char_one: 'lucy',
    char_two: 'ben',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 319,
    avg_round: 12950,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 319,
    avg_round: 18740,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 17149,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 319,
    avg_round: 17971,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 319,
    avg_round: 24488,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 13549,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 319,
    avg_round: 21587,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 14752,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 319,
    avg_round: 17714,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 9127,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 319,
    avg_round: 10726,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 319,
    avg_round: 12136,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.05,
    rank: 319,
    avg_round: 20401,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 17544,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 319,
    avg_round: 25557,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 319,
    avg_round: 11724,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lycaon',
    app_rate: 0.05,
    rank: 319,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 13410,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 10970,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 319,
    avg_round: 13398,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'lighter',
    app_rate: 0.05,
    rank: 319,
    avg_round: 16227,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.03,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.03,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'grace-howard',
    app_rate: 0.03,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.03,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.03,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.03,
    rank: 369,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soukaku',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'pulchra',
    char_three: 'soukaku',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'burnice',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'ben',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'pulchra',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'burnice',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nekomata',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'grace-howard',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'piper',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'jane-doe',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara-soldier-0',
    char_three: 'yanagi',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'billy-kid',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nekomata',
    char_three: 'yanagi',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'astra-yao',
    char_two: 'ben',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lycaon',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'pulchra',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'corin',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'jane-doe',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'pulchra',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nekomata',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 469,
    avg_round: 0,
    star_num: '4'
  }
];
