export const ZZZAssault_1 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 20.15,
    rank: 1,
    avg_round: 23701,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 7.71,
    rank: 2,
    avg_round: 21070,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 4.64,
    rank: 3,
    avg_round: 22179,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 2.81,
    rank: 4,
    avg_round: 31739,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 2.68,
    rank: 5,
    avg_round: 26017,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 2.38,
    rank: 6,
    avg_round: 20724,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 2.25,
    rank: 7,
    avg_round: 25439,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 2.07,
    rank: 8,
    avg_round: 16059,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 1.62,
    rank: 9,
    avg_round: 24266,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 1.56,
    rank: 10,
    avg_round: 30885,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 1.49,
    rank: 11,
    avg_round: 15112,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.43,
    rank: 12,
    avg_round: 15824,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.4,
    rank: 13,
    avg_round: 24070,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 1.34,
    rank: 14,
    avg_round: 24223,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 1.25,
    rank: 15,
    avg_round: 25639,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 1.23,
    rank: 16,
    avg_round: 26066,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.21,
    rank: 17,
    avg_round: 24296,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 1.06,
    rank: 18,
    avg_round: 22597,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.99,
    rank: 19,
    avg_round: 12817,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.93,
    rank: 20,
    avg_round: 20975,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.91,
    rank: 21,
    avg_round: 22534,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'caesar',
    app_rate: 0.86,
    rank: 22,
    avg_round: 22182,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.8,
    rank: 23,
    avg_round: 19991,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.8,
    rank: 23,
    avg_round: 20575,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.78,
    rank: 25,
    avg_round: 25833,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.78,
    rank: 25,
    avg_round: 19383,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.71,
    rank: 27,
    avg_round: 19026,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.71,
    rank: 27,
    avg_round: 28747,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.61,
    rank: 29,
    avg_round: 27216,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'rina',
    app_rate: 0.61,
    rank: 29,
    avg_round: 15956,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.6,
    rank: 31,
    avg_round: 16499,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'nicole-demara',
    app_rate: 0.6,
    rank: 31,
    avg_round: 20471,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.54,
    rank: 33,
    avg_round: 33670,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.54,
    rank: 33,
    avg_round: 16511,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.5,
    rank: 35,
    avg_round: 21311,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.48,
    rank: 36,
    avg_round: 23534,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.47,
    rank: 37,
    avg_round: 24375,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.47,
    rank: 37,
    avg_round: 17639,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.45,
    rank: 39,
    avg_round: 18598,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.45,
    rank: 39,
    avg_round: 19586,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.45,
    rank: 39,
    avg_round: 19591,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.43,
    rank: 42,
    avg_round: 18899,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 42,
    avg_round: 16869,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.39,
    rank: 44,
    avg_round: 22580,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.37,
    rank: 45,
    avg_round: 14757,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.35,
    rank: 46,
    avg_round: 28359,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.34,
    rank: 47,
    avg_round: 20150,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.32,
    rank: 48,
    avg_round: 22663,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.3,
    rank: 49,
    avg_round: 13378,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.3,
    rank: 49,
    avg_round: 19015,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.3,
    rank: 49,
    avg_round: 24829,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.28,
    rank: 52,
    avg_round: 15811,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.28,
    rank: 52,
    avg_round: 26089,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.28,
    rank: 52,
    avg_round: 16196,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.28,
    rank: 52,
    avg_round: 13752,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.26,
    rank: 56,
    avg_round: 21129,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.24,
    rank: 57,
    avg_round: 21507,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.24,
    rank: 57,
    avg_round: 14067,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.24,
    rank: 57,
    avg_round: 11885,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.24,
    rank: 57,
    avg_round: 18150,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.24,
    rank: 57,
    avg_round: 16329,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.24,
    rank: 57,
    avg_round: 19483,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 63,
    avg_round: 14074,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.22,
    rank: 63,
    avg_round: 19542,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 63,
    avg_round: 11358,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.22,
    rank: 63,
    avg_round: 20380,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.22,
    rank: 63,
    avg_round: 20518,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.22,
    rank: 63,
    avg_round: 28521,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 69,
    avg_round: 17705,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.2,
    rank: 69,
    avg_round: 33728,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.19,
    rank: 71,
    avg_round: 21135,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.19,
    rank: 71,
    avg_round: 20428,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 71,
    avg_round: 12828,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.19,
    rank: 71,
    avg_round: 25878,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 71,
    avg_round: 35528,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.19,
    rank: 71,
    avg_round: 15410,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.19,
    rank: 71,
    avg_round: 10152,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.19,
    rank: 71,
    avg_round: 11338,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 71,
    avg_round: 18442,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.17,
    rank: 80,
    avg_round: 20354,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 80,
    avg_round: 20320,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 80,
    avg_round: 17141,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 83,
    avg_round: 16918,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.15,
    rank: 83,
    avg_round: 23245,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 83,
    avg_round: 12973,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 83,
    avg_round: 19830,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 83,
    avg_round: 20645,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 83,
    avg_round: 22114,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 83,
    avg_round: 21114,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 83,
    avg_round: 29005,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.15,
    rank: 83,
    avg_round: 9574,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.15,
    rank: 83,
    avg_round: 13928,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 93,
    avg_round: 19725,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 93,
    avg_round: 21889,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 93,
    avg_round: 18706,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 93,
    avg_round: 21004,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 93,
    avg_round: 25532,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 93,
    avg_round: 29218,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 93,
    avg_round: 17891,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 93,
    avg_round: 11104,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 93,
    avg_round: 19218,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 93,
    avg_round: 23096,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 93,
    avg_round: 19387,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.13,
    rank: 93,
    avg_round: 10065,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 105,
    avg_round: 24244,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 105,
    avg_round: 24973,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 105,
    avg_round: 20310,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 105,
    avg_round: 15750,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 105,
    avg_round: 18042,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 105,
    avg_round: 18920,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 105,
    avg_round: 22752,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 105,
    avg_round: 17712,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 105,
    avg_round: 21571,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 105,
    avg_round: 24100,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 105,
    avg_round: 16383,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 105,
    avg_round: 14020,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 105,
    avg_round: 32585,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 105,
    avg_round: 22264,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 105,
    avg_round: 19379,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 120,
    avg_round: 15222,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 120,
    avg_round: 15680,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 120,
    avg_round: 25199,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'pulchra',
    app_rate: 0.09,
    rank: 120,
    avg_round: 20270,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 120,
    avg_round: 21838,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 120,
    avg_round: 25621,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 120,
    avg_round: 22066,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 120,
    avg_round: 19297,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 120,
    avg_round: 22237,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 120,
    avg_round: 7388,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 120,
    avg_round: 18065,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 120,
    avg_round: 9982,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 120,
    avg_round: 16954,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 133,
    avg_round: 13345,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 133,
    avg_round: 15638,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 133,
    avg_round: 24800,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 133,
    avg_round: 16057,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 133,
    avg_round: 16051,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 133,
    avg_round: 11722,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 133,
    avg_round: 22144,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 19100,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 133,
    avg_round: 15972,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 133,
    avg_round: 18338,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 133,
    avg_round: 11529,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 14138,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 14450,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 133,
    avg_round: 18836,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.07,
    rank: 133,
    avg_round: 9810,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 133,
    avg_round: 21839,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 133,
    avg_round: 14831,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 133,
    avg_round: 11495,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 133,
    avg_round: 28683,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 133,
    avg_round: 14676,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 133,
    avg_round: 21107,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 133,
    avg_round: 15030,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 155,
    avg_round: 24312,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 155,
    avg_round: 22701,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 155,
    avg_round: 25524,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 155,
    avg_round: 21638,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 155,
    avg_round: 17633,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 155,
    avg_round: 21888,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 155,
    avg_round: 10394,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 155,
    avg_round: 24488,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 155,
    avg_round: 20136,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 155,
    avg_round: 21587,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 155,
    avg_round: 17714,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 155,
    avg_round: 23272,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 155,
    avg_round: 19744,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 155,
    avg_round: 23571,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 155,
    avg_round: 28534,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 155,
    avg_round: 10720,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 155,
    avg_round: 13938,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.06,
    rank: 155,
    avg_round: 15310,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 155,
    avg_round: 11527,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 155,
    avg_round: 19247,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 155,
    avg_round: 11724,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 155,
    avg_round: 11382,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 155,
    avg_round: 25298,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 155,
    avg_round: 13124,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 155,
    avg_round: 22316,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 155,
    avg_round: 16402,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 155,
    avg_round: 25139,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 155,
    avg_round: 13410,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 155,
    avg_round: 10970,
    star_num: '4'
  }
];
